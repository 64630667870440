<template>
    <div class="pageMain">
      <!-- 企业备案 -->
      <div class="content">
        <!-- 左边列表 -->
        <CustomTables
          :data="tableData"
          height="55vh"
          :total="total"
          class="tableHear"
          :headerCellStyle="headerCellStyle"
         :titleIcon="true"
        >
          <template slot="search">
            <SearchLeft
              @search="search"
              @reset="reset"
              :iconShow="true"
              :infoShow="true"
              info="项目信息"
            >
            <el-form :inline="true" :model="searchForm">
              <el-form-item class="formItem">
                <el-input
                  v-model="searchForm.user"
                  size="small"
                  placeholder="项目"
                ></el-input>
              </el-form-item>
            </el-form>
            </SearchLeft>
          </template>
         
          <el-table-column type="index" label="排序" width="50"  align="center">
          </el-table-column>
          <el-table-column prop="date" label="项目名称"  align="center"> </el-table-column>
          <el-table-column prop="date" label="总报(次)"  align="center"> </el-table-column>
        </CustomTables>
        <div class="tableBox">
        
          <CustomTables :data="tableData" :headerCellStyle="headerCellStyle"  height="250"  :total="total" title="项目设备列表"   @selectionChange="handleSelectionChange" class="tables">
           
            <template slot="tabs">
              <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
              v-for="(tab,index) in tabs"
              :key="tab.name"
              :label="tab.label"
              :name="tab.name"
            >
              <!-- <span>{{ tab.label }}</span> -->
            </el-tab-pane>
          </el-tabs>
            </template>
            <el-table-column type="selection" align="center" width="55">
      </el-table-column>
            <el-table-column prop="date" label="现场编号"  align="center"> </el-table-column>
            <el-table-column prop="date" label="设备类型"  align="center"> </el-table-column>
            <el-table-column prop="date" label="起重设备编号"  align="center"> </el-table-column>
            <el-table-column prop="date" label="监控主机IMEI"  align="center"> </el-table-column>
            <el-table-column prop="date" label="违章次数"  align="center"> </el-table-column>
          </CustomTables>
          <CustomTables :data="tableData" :headerCellStyle="headerCellStyle"  height="250"  :total="total" title="违章详情"  @selectionChange="handleSelectionChanges">
            <template slot="title">
              <div class="title">报警详情</div>
            </template>
            <el-table-column type="selection" align="center" width="55">
      </el-table-column>
            <el-table-column prop="date" label="运行时间"  align="center"> </el-table-column>
            <el-table-column prop="name" label="违章类型"  align="center"> </el-table-column>
            <el-table-column prop="name" label="运行状态"  align="center"> </el-table-column>
            <el-table-column label="详情" align="center" width="100">
            <template slot-scope="scope">
              <span
                @click="detailItem(scope.row)"
                class="detail"
              >查看</span>
            </template>
          </el-table-column>
          </CustomTables>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import CustomTables from "../../../components/common/CustomTables";
  import SearchHead from "../../../components/common/SearchHead";
  import SearchLeft from "../../../components/common/SearchLeft.vue";
  export default {
    components: { CustomTables, SearchHead,SearchLeft },
    name: "violation",
    data() {
      return {
        tableData: [
          {
            date: "2016-05-02",
            name: "王小虎",
          },
          {
            date: "2016-05-02",
            name: "王小虎",
          },
          {
            date: "2016-05-02",
            name: "王小虎",
          },
          {
            date: "2016-05-02",
            name: "王小虎",
          },
          {
            date: "2016-05-02",
            name: "王小虎",
          },
          {
            date: "2016-05-02",
            name: "王小虎",
          },
          {
            date: "2016-05-02",
            name: "王小虎",
          },
          {
            date: "2016-05-02",
            name: "王小虎",
          },
        ],
        headerCellStyle: {
          backgroundColor: "#f3f2f7",
          color: "#373628",
        },
        searchForm: {},
        activeName: "tower",
        tabs:[{
          label:'塔机',
          name:'tower'
        },{
          label:'升降机',
          name:'towers'
  
        }],
        total:0
      };
    },
    mounted() {},
    methods: {
      search() {
        console.log("searchForm", this.searchForm);
      },
      reset() {
        this.searchForm = {};
      },
      handleClick(tab, event) {
        console.log(tab, event);
      },
      detailItem(){},
      handleSelectionChange(){},
      handleSelectionChanges(){}
    },
  };
  </script>
  
  <style scoped lang="less">
  .content {
    display: flex;
    justify-content: space-between;
  }
  .tableBox {
    // margin-left: 10px;
    width: 67%;
  }
  .tableHear {
    width: 30%;
    // background:#e4ebf1
  }
  .title {
    margin-bottom: 10px;
  color: #000;
  }
  
  .formItem{
    margin-bottom: 0px;
  }
  .tables{
    margin-bottom: 10px;
  }
  </style>
  